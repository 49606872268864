import http from '~/http-common'
import type { CalendarDatasRes } from '~~/types/calendarDatasRes'
import { dateFormatter } from '~/libs/dateFormatter'
import { makeQueryStrings } from '~/libs/makeQueryStrings'
import { API_URL } from '~/const/env'
import { ApiPath } from '~/enum/apiPath'

class GetCalendarDatasService {
  getCalendarDatas(targetDate: Date): Promise<CalendarDatasRes> {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const path = ApiPath.GET_CALENDAR_DATAS_GET_CALENDAR_DATAS
    const params = {
      tgtYmdStr: dateFormatter(targetDate, 'YYYYMMDD', ''),
      dataFlg: '1',
      url: API_URL + ApiPath.GUARD_F30101,
    }
    const queryStrings: string = makeQueryStrings(params)

    return http.get(path + queryStrings, { headers: headers })
  }
}
export default new GetCalendarDatasService()
